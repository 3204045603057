import React, { useState, useEffect } from "react";
import makeDynamicFormId from "../helpers/makeDynamicFormId";
import { AccruentImg, Loader } from "../commonComponents";
import useMarketoForm from "../helpers/useMarketoForm";
import { DEMO_FORM_LOADED } from "src/constants";

const DemoFormInfo = ({
  serial,
  formId,
  gaLabel,
  description
}) => {
  const [isFormLoaded, setFormLoaded] = useState(false);
  const formCallback = () => {
    setFormLoaded(true);
    localStorage.setItem(DEMO_FORM_LOADED, true)
    window.dispatchEvent(new CustomEvent("isFormLoaded", { 
      detail: {
        newValue: true,
        key: DEMO_FORM_LOADED
      }
    }));
  };
  useEffect(() => {
    localStorage.removeItem(DEMO_FORM_LOADED, false)
    window.dispatchEvent(new CustomEvent("isFormLoaded", { 
      detail: {
        newValue: false,
        key: DEMO_FORM_LOADED
      }
    }));
  }, []);
  const { formTag } = useMarketoForm(serial, formId, formCallback, "", gaLabel);

  return (
    <>
      {isFormLoaded && <div className="background"></div>}
      {formTag && (
        <div className={`column second-column ${!!description ? '': 'set-margin'}`}>
          <div className="control" style={{ position: "initial" }}>
            {!isFormLoaded && (
              <div>
                <Loader />
              </div>
            )}
            <div
              className={`${!isFormLoaded ? "unset-display" : "set-display"}`}
            >
              {formTag}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const DemoForm = ({
  image,
  description,
  fieldFormHeader,
  fieldMarketoFormTitleId = null,
  serial,
  formId,
  gaLabel,
  shouldLoad = true,
}) => {
  let id = fieldMarketoFormTitleId
    ? makeDynamicFormId(fieldMarketoFormTitleId)
    : "request-a-demo";
  return (
    <>
      <div className="solutions-get-in-touch-wrapper demo-form popup-form-handle">
        <div id={id}></div>
        {formId && (
          <>
            <div className="columns is-centered">
              {(fieldFormHeader || image || description) && (
                <div className="column is-5 first-column">
                  {fieldFormHeader && (
                    <h2 className="title has-text-centered-mobile title-3">
                      {fieldFormHeader}
                    </h2>
                  )}
                  {image && (
                    <AccruentImg file={image} className="demo-image mb-3" />
                  )}
                  {description && (
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                      className="dangerously-html-handle"
                    />
                  )}
                  <div className="test"></div>
                </div>
              )}
              {shouldLoad && (
                <DemoFormInfo
                  serial={serial}
                  formId={formId}
                  gaLabel={gaLabel}
                  description={description}
                />
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DemoForm;
